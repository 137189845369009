<template>
  <div>
    <div class="brand-list w-1200">
      <div class="brand-item" v-for="item in brandList" :key="item.id" @click="toBrandGoods(item)">
        <img v-lazy="item.cover"/>
      </div>
    </div>
    <!-- 分页区域 -->
    <div class="w-1200">
      <el-pagination class="mb-1"
        :current-page="brandListQueryInfo.page"
        :page-sizes="[35, 50, 70, 100]"
        :page-size="brandListQueryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="brandListTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import { getBrandListUrl } from '@/api/index'
export default {
  name: 'BrandList',
  data(){
    return{
      brandList:[],
      brandListQueryInfo:{
        pageSize: 35,
        page: 1,
        field: '',
        desc: '',
      },
      brandListTotal:0,
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getBrandList()
    },
    // 获取品牌列表
    async getBrandList(){
      const { data: res } = await this.$http.get(getBrandListUrl,{params:this.brandListQueryInfo})
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.brandList = res.data.list
      this.brandListTotal = res.data.total
    },
    // 跳转到品牌商品列表页面
    toBrandGoods(brandInfo){
      console.log(brandInfo)
      this.$router.push({ path: '/brandGoods', query: { brandId:brandInfo.id } })
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.brandListQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getBrandList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.brandListQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getBrandList()
    },
  }
}
</script>

<style lang="less" scoped>
.brand-list{
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  margin-top: 10px;
  .brand-item{
    padding: 10px;
    margin: 0 20px 10px 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    cursor: pointer;
    img{
      width: 130px;
      height: 40px;
      transition: 1s;
      display: block;
      &:hover{
        transform: scale(1.1);
      }
    }
    &:nth-child(7n){
      margin-right: 0;
    }
  }
}
</style>
